/* eslint-disable react/no-array-index-key */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useMemo, useCallback, useEffect } from "react";
import { useIntl } from "react-intl";
import { useDispatch, useSelector } from "react-redux";
import { Creators as AuthActions } from "store/ducks/auth";
import {
  validateEmail,
  validateCPF,
  validateDate,
  validateCNPJ,
} from "helpers/sharedHelpers";

import Button from "components/core/Button/Button";
import { Input, InputMask, Select, CheckBox } from "components/core/Form/Form";
import Flex from "components/core/Grid/Flex";

import { style } from "../../../style";
import * as S from "./SignUpStyle";

const teamsActivities = {
  "Pintou Parceria Suvinil": [
    { id: "consumer", value: "consumer", text: "Consumidor" },
    { id: "painter", value: "painter", text: "Pintor" },
    {
      id: "become_painter",
      value: "become_painter",
      text: "Quero me formar pintor",
    },
    { id: "clerk", value: "clerk", text: "Vendedor / Balconista" },
    { id: "architec", value: "architec", text: "Arquiteto / Decorador" },
  ],
  sample: [
    { id: "consumer", value: "consumer", text: "Consumidor" },
    { id: "painter", value: "painter", text: "Pintor" },
    {
      id: "become_painter",
      value: "become_painter",
      text: "Quero me formar pintor",
    },
    { id: "clerk", value: "clerk", text: "Vendedor / Balconista" },
    { id: "architec", value: "architec", text: "Arquiteto / Decorador" },
  ],
  Vedacit: [],
  mestreconstrutor: [],
};

const SignUp = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const [errorPass, setErrorPass] = useState();
  const {
    isLoading,
    listState,
    listCities,
    listActivities,
    terms_url,
    lgpd_url,
  } = useSelector((state) => state.auth);
  const [listCitiesState, setListCitiesState] = useState(listCities);
  const [showCities, setShowCities] = useState(false);
  const [formCheckValues, setFormCheckValues] = useState({
    pageSelect: 0,
    checkFormList: [false, false, false, false],
  });
  const isVedacit = style.names.nameApp === "Vedacit";
  const isSuvinil = style.names.nameApp === "Pintou Parceria Suvinil";
  const isMc = style.names.nameApp === "mestreconstrutor";
  const [activitiesListClient, setActivitiesListClient] =
    useState(teamsActivities);

  const listSexGender = [
    {
      text: "Mulher cis",
      id: 1,
      value: "cis_woman",
      title: "Nasceu com orgão genital feminino e se identifica como mulher",
    },
    {
      text: "Mulher trans",
      id: 2,
      value: "trans_woman",
      title: "Nasceu com órgão genital masculino e se identifica como mulher",
    },
    {
      text: "Homem cis",
      id: 3,
      value: "cis_man",
      title: "Nasceu com órgão genital masculino e se identifica como homem",
    },
    {
      text: "Homem trans",
      id: 4,
      value: "trans_man",
      title: "Nasceu com órgão genital feminino e se identifica como homem",
    },
    {
      text: "Não binário",
      id: 5,
      value: "not_binary",
      title: "Não se identifica especificamente como ou homem ou como mulher",
    },
    {
      text: "Prefere não se identificar",
      id: 6,
      value: "prefers_not_to_identify",
    },
    {
      text: "Outro",
      id: 7,
      value: "other",
    },
  ];

  const listAppDiscovery = [
    {
      id: 1,
      text: "Treinamentos",
      value: "1",
    },
    {
      id: 2,
      text: "Eventos",
      value: "2",
    },
    {
      id: 3,
      text: "Site Suvinil",
      value: "3",
    },
    {
      id: 4,
      text: "Loja de tintas",
      value: "4",
    },
    {
      id: 5,
      text: "Redes sociais",
      value: "5",
    },
    {
      id: 6,
      text: "Indicação",
      value: "6",
    },
    {
      id: 7,
      text: "Outros",
      value: "7",
    },
  ];

  // const validateUsername = /^[a-zA-Z0-9]+(?:[.\w-]*[a-zA-Z0-9]+)*$/;

  const [form, setForm] = useState({
    name: "",
    cpf: "",
    sex: "",
    custom_gender: "",
    login: "",
    phone: "",
    email: "",
    datebirthday: "",
    zip_code: "",
    street: "",
    neighborhood: "",
    state_id: "",
    uf: "",
    city_id: "",
    city: "",
    number: "",
    complement: "",
    password: "",
    password_confirmation: "",
    preferred_name: "",
    terms: false,
    lgpd: false,
    auto_declared: "",
    user_activity_id: "",
    cnpj: "",
    app_discovery_id: "",
  });

  const formActivityCheck = form.auto_declared || form.user_activity_id;

  const dispatchGetStateList = useCallback(
    () => dispatch(AuthActions.getStateList()),
    [dispatch],
  );

  const dispatchGetCitiesList = useCallback(
    (CitiesId) => dispatch(AuthActions.getCitiesList({ id: CitiesId })),
    [dispatch],
  );

  const dispatchGetActivitiesList = useCallback(
    () => dispatch(AuthActions.getActivitiesList()),
    [dispatch],
  );

  useEffect(() => {
    form.state_id !== undefined && dispatchGetCitiesList(form.state_id);
  }, [form.state_id]);

  useEffect(() => {
    dispatch(AuthActions.getTermOfUse());
    dispatch(AuthActions.getLgpd());
  }, []);

  useEffect(() => {
    dispatchGetStateList();
    localStorage.clear();
  }, [dispatchGetStateList]);

  useEffect(() => {
    setListCitiesState(listCities);

    const findCity = listCities.find((item) => item.text === form.city);

    if (findCity !== undefined)
      setForm({
        ...form,
        city_id: findCity.id,
      });
  }, [listCities]);

  useEffect(() => {
    dispatchGetActivitiesList();
    localStorage.clear();
  }, [dispatchGetActivitiesList]);

  useEffect(() => {
    if (isVedacit && !isSuvinil && !isMc) {
      setActivitiesListClient({
        ...activitiesListClient,
        Vedacit: listActivities,
      });
    }
    if (isMc && !isVedacit && !isSuvinil) {
      setActivitiesListClient({
        ...activitiesListClient,
        mestreconstrutor: listActivities,
      });
    }
  }, [listActivities]);

  const formCheckValidate = (values, formCheck, valueType) => {
    if (!values && formCheck) {
      return intl.messages["general.buttons.inputRequired"];
    }
    if (valueType && formCheck) {
      if (valueType === "email" && !validateEmail(form.email)) {
        return intl.messages["general.buttons.inputEmailInvalid"];
      }
      if (valueType === "phone" && form.phone.length !== 16) {
        return intl.messages["general.buttons.inputPhoneInvalid"];
      }
      // if (valueType === "login" && !validateUsername.test(form.login)) {
      //   return intl.messages["general.buttons.loginInvalid"];
      // }
    }

    return false;
  };

  const handleSendCities = (valueId) => {
    dispatchGetCitiesList(valueId);
    let new_uf;
    listState.map((state) => {
      if (state.id == valueId) new_uf = state.uf;
    });
    setForm({ ...form, state_id: valueId, uf: new_uf, city: "", city_id: "" });
    setShowCities(false);
  };

  const handleActivity = (valueId) => {
    if (isVedacit || isMc) {
      setForm({ ...form, user_activity_id: valueId });
    } else {
      setForm({ ...form, auto_declared: valueId });
    }
  };

  const handleSearchCities = (nameCity) => {
    if (nameCity.length > 0) {
      const filterCities = listCities?.filter(
        (cities) =>
          cities.text?.toLowerCase().indexOf(nameCity.toLowerCase().trim()) >
            -1 && cities,
      );
      setShowCities(true);
      setListCitiesState(filterCities);
      if (
        filterCities.length === 1 &&
        filterCities[0].text === nameCity.trim()
      ) {
        setForm({ ...form, city_id: filterCities[0].id });
        setShowCities(false);
      }
    }
    setForm({ ...form, city: nameCity });
  };

  const handleClick = (cities) => {
    setShowCities(false);
    setForm({
      ...form,
      city: cities.text,
      city_id: cities.id,
    });
  };

  const handleCep = (value) => {
    setForm({ ...form, zip_code: value });

    if (value.length === 9) {
      fetch(`https://viacep.com.br/ws/${value.replace("-", "")}/json/`).then(
        (response) => {
          response.json().then((data) => {
            const { localidade, logradouro, cep, uf, bairro } = data;
            const findUf = listState.find((item) => item.uf === uf);

            setForm({
              ...form,
              city: localidade,
              street: logradouro,
              zip_code: cep,
              neighborhood: bairro,
              state_id: findUf.id,
              uf,
            });
          });
        },
      );
    }
  };

  const formCarrousel = useMemo(
    () => [
      <>
        {(isSuvinil || style.names.nameApp === "sample") && (
          <Select
            value={form.app_discovery_id}
            error={
              !isVedacit &&
              formCheckValidate(
                form.app_discovery_id,
                formCheckValues.checkFormList[0],
              )
            }
            options={listAppDiscovery}
            label={`${intl.messages["profile.appDiscovery"]}${
              isVedacit ? "" : "*"
            }`}
            onChange={(e) =>
              setForm({ ...form, app_discovery_id: e.target.value })
            }
          />
        )}
        <Input
          type="text"
          name="name"
          error={formCheckValidate(form.name, formCheckValues.checkFormList[0])}
          placeholder={intl.messages["profile.textPage.typeYourName"]}
          label={`${intl.messages["profile.name"]}*`}
          onChange={(e) => setForm({ ...form, name: e.target.value })}
          value={form.name}
        />
        <Input
          type="text"
          name="preferred_name"
          placeholder={intl.messages["profile.textPage.typeYourNickname"]}
          label={`${intl.messages["profile.nickname"]}`}
          onChange={(e) => setForm({ ...form, preferred_name: e.target.value })}
          value={form.preferred_name}
        />

        <InputMask
          mask="99/99/9999"
          maskChar={null}
          type="text"
          name="datebirthday"
          value={form.datebirthday}
          error={
            !isVedacit &&
            !isMc &&
            !validateDate(form.datebirthday) &&
            formCheckValues.checkFormList[0] &&
            intl.messages["general.buttons.inputDateInvalid"]
          }
          placeholder={intl.messages["profile.textPage.typeYourBirthday"]}
          label={`${intl.messages["profile.datebirthday"]}${
            isVedacit || isMc ? "" : "*"
          }`}
          onChange={(e) => {
            setForm({ ...form, datebirthday: e.target.value });
          }}
        />
        <InputMask
          mask="999.999.999-99"
          maskChar={null}
          type="text"
          name="email"
          error={
            !validateCPF(form.cpf) &&
            formCheckValues.checkFormList[0] &&
            intl.messages["general.buttons.inputCPFInvalid"]
          }
          placeholder={intl.messages["profile.textPage.typeYourCPF"]}
          label={`${intl.messages["profile.cpf"]}*`}
          onChange={(e) => setForm({ ...form, cpf: e.target.value })}
          value={form.cpf}
        />
        <Select
          value={form.sex}
          error={
            !isVedacit &&
            !isMc &&
            formCheckValidate(form.sex, formCheckValues.checkFormList[0])
          }
          options={listSexGender}
          label={`${intl.messages["profile.gender"]}${
            isVedacit || isMc ? "" : "*"
          }`}
          onChange={(e) => setForm({ ...form, sex: e.target.value })}
        />
        {form.sex === "other" && (
          <Input
            name="name"
            value={form.custom_gender}
            label={`${intl.messages["profile.otherGender"]}`}
            placeholder={`${intl.messages["profile.textPage.typeYourGender"]}`}
            onChange={(e) =>
              setForm({ ...form, custom_gender: e.target.value })
            }
          />
        )}
        {activitiesListClient[style.names.nameApp] && (
          <Select
            value={
              isVedacit || isMc ? form.user_activity_id : form.auto_declared
            }
            error={formCheckValidate(
              isVedacit || isMc ? form.user_activity_id : form.auto_declared,
              formCheckValues.checkFormList[0],
            )}
            options={activitiesListClient[style.names.nameApp]}
            label={
              isMc
                ? `${intl.messages["profile.profession"]}*`
                : `${intl.messages["profile.activity"]}*`
            }
            onChange={(e) => handleActivity(e.target.value)}
          />
        )}

        {(isSuvinil || style.names.nameApp === "sample") &&
          form.auto_declared === "clerk" && (
            <InputMask
              mask="99.999.999/9999-99"
              maskChar={null}
              type="text"
              name="cnpj"
              error={
                !validateCNPJ(form.cnpj) &&
                formCheckValues.checkFormList[0] &&
                intl.messages["general.buttons.inputCNPJInvalid"]
              }
              placeholder={intl.messages["profile.textPage.typeYourCNPJ"]}
              label={`${intl.messages["profile.cnpj"]}*`}
              onChange={(e) => setForm({ ...form, cnpj: e.target.value })}
              value={form.cnpj}
            />
          )}
      </>,
      <>
        <Input
          type="text"
          name="sendemail"
          error={
            !validateEmail(form.email) &&
            formCheckValues.checkFormList[1] &&
            intl.messages["general.buttons.inputEmailInvalid"]
          }
          placeholder={intl.messages["profile.textPage.typeYourEmail"]}
          label={`${intl.messages["general.email"]}*`}
          onChange={(e) => setForm({ ...form, email: e.target.value })}
          value={form.email}
        />
        <InputMask
          mask="(99) 9 9999-9999"
          maskChar={null}
          type="text"
          name="phone"
          value={form.phone}
          error={formCheckValidate(
            form.phone,
            formCheckValues.checkFormList[1],
          )}
          placeholder={intl.messages["profile.textPage.typePhone"]}
          label={`${intl.messages["profile.cellPhone"]}*`}
          onChange={(e) => setForm({ ...form, phone: e.target.value })}
        />
        <InputMask
          mask="99999-999"
          maskChar={null}
          type="text"
          name="address[zip_code]"
          error={
            !isVedacit &&
            !isMc &&
            formCheckValidate(form.zip_code, formCheckValues.checkFormList[1])
          }
          placeholder={intl.messages["profile.textPage.typeYourCEP"]}
          label={`${intl.messages["profile.cep"]}${
            isVedacit || isMc ? "" : "*"
          }`}
          onChange={(e) => handleCep(e.target.value)}
          value={form.zip_code}
        />
        <Input
          type="text"
          name="address[streeet]"
          error={
            !isVedacit &&
            !isMc &&
            formCheckValidate(form.street, formCheckValues.checkFormList[1])
          }
          placeholder={intl.messages["profile.textPage.typeYourAddress"]}
          label={`${intl.messages["profile.address"]}${
            isVedacit || isMc ? "" : "*"
          }`}
          onChange={(e) => setForm({ ...form, street: e.target.value })}
          value={form.street}
        />
        <Flex>
          <Flex mr={9}>
            <Input
              type="number"
              name="address[number]"
              error={formCheckValidate(
                form.number,
                formCheckValues.checkFormList[1],
              )}
              placeholder={intl.messages["profile.textPage.typerYourNumber"]}
              label={`${intl.messages["profile.number"]}*`}
              onChange={(e) => setForm({ ...form, number: e.target.value })}
              value={form.number}
            />
          </Flex>
          <Flex ml={9}>
            <Input
              type="text"
              name="address[complement]"
              placeholder={intl.messages["profile.textPage.typeYourComplement"]}
              label={`${intl.messages["profile.complement"]}`}
              onChange={(e) => setForm({ ...form, complement: e.target.value })}
              value={form.complement}
            />
          </Flex>
        </Flex>
        <Input
          type="text"
          name="address[neighborhood]"
          error={
            !isVedacit &&
            !isMc &&
            formCheckValidate(
              form.neighborhood,
              formCheckValues.checkFormList[1],
            )
          }
          placeholder={intl.messages["profile.textPage.typeYourNeighborhood"]}
          label={`${intl.messages["profile.neighborhood"]}${
            isVedacit || isMc ? "" : "*"
          }`}
          onChange={(e) => setForm({ ...form, neighborhood: e.target.value })}
          value={form.neighborhood}
        />
        <Select
          value={form.state_id}
          error={formCheckValidate(
            form.state_id,
            formCheckValues.checkFormList[1],
          )}
          options={listState}
          label={`${intl.messages["profile.state"]}*`}
          onChange={(e) => handleSendCities(e.target.value)}
        />
        <S.ContentSelectCity>
          <S.ListCities cityValidation={showCities && form.city}>
            {showCities &&
              listCitiesState.map((cities) => (
                <S.ContentCities
                  key={cities.id}
                  onClick={() => handleClick(cities)}
                >
                  {cities.text}
                </S.ContentCities>
              ))}
          </S.ListCities>
          <Input
            type="text"
            name="city"
            error={formCheckValidate(
              form.city,
              formCheckValues.checkFormList[1],
            )}
            placeholder={intl.messages["login.textPage.typeYourCity"]}
            label={`${intl.messages["profile.city"]}*`}
            onChange={(e) => handleSearchCities(e.target.value)}
            value={form.city}
            autocomplete="your_city"
          />
        </S.ContentSelectCity>
      </>,
      <>
        <Input
          type="text"
          name="login"
          placeholder={intl.messages["login.textPage.createYourUser"]}
          label={`${intl.messages["general.user"]}*`}
          value={form.cpf}
          disabled
        />
        <Input
          type="password"
          name="newPassword"
          error={formCheckValidate(
            form.password,
            formCheckValues.checkFormList[2],
          )}
          placeholder={intl.messages["settings.textPage.createYourNewPassword"]}
          label={`${intl.messages["general.password"]}*`}
          onChange={(e) => setForm({ ...form, password: e.target.value })}
        />
        <Input
          type="password"
          name="password_confirmation"
          error={
            errorPass ||
            formCheckValidate(
              form.password_confirmation,
              formCheckValues.checkFormList[2],
            )
          }
          placeholder={
            intl.messages["settings.textPage.typeYourConfirmPassword"]
          }
          label={`${intl.messages["settings.confirmPassword"]}*`}
          onChange={(e) =>
            setForm({ ...form, password_confirmation: e.target.value })
          }
        />
        <S.Terms>
          <CheckBox
            id="terms"
            label={`${
              intl.messages["profile.readAndAccepted"]
            } <a href=${terms_url} target="_blank" class="link">${
              isVedacit || isMc
                ? intl.messages["profile.termOfUseAndPolicy"]
                : intl.messages["profile.termOfUse"]
            }</a>`}
            labelSize="14px"
            name="terms"
            error={formCheckValidate(
              form.terms,
              formCheckValues.checkFormList[2],
            )}
            onChange={(e) => {
              setForm({ ...form, terms: e.target.checked });
            }}
            isVedacit={isVedacit || isMc}
          />
          {(isSuvinil || style.names.nameApp === "sample") && (
            <CheckBox
              id="lgpd"
              label={`${intl.messages["profile.readAndAccepted"]} <a href=${lgpd_url} target="_blank" class="link">${intl.messages["profile.lgpd"]}</a>`}
              labelSize="14px"
              name="lgpd"
              error={formCheckValidate(
                form.lgpd,
                formCheckValues.checkFormList[2],
              )}
              onChange={(e) => {
                setForm({ ...form, lgpd: e.target.checked });
              }}
            />
          )}
        </S.Terms>
      </>,
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [form, formCheckValues, activitiesListClient[style.names.nameApp]],
  );

  const handleFormCheckValues = (page, newValuesCheck) => {
    setFormCheckValues({
      ...formCheckValues,
      pageSelect: page,
      checkFormList: newValuesCheck,
    });
  };

  const handleSignUp = (page, selectProgress) => {
    const valuesCheckForm = formCheckValues.checkFormList;

    if (page < formCheckValues.pageSelect) {
      setFormCheckValues({
        ...formCheckValues,
        pageSelect: page,
      });
      return;
    }

    switch (formCheckValues.pageSelect) {
      case 0:
        valuesCheckForm[0] = true;
        if (
          !form.name ||
          (!isVedacit &&
            !isMc &&
            !form.datebirthday &&
            !validateDate(form.datebirthday)) ||
          (!form.cpf && !validateCPF(form.cpf)) ||
          !formActivityCheck ||
          (!isVedacit && !isMc && !form.sex) ||
          (!isVedacit && !form.app_discovery_id)
        ) {
          handleFormCheckValues(0, valuesCheckForm);
        } else {
          handleFormCheckValues(selectProgress ? page : 1, valuesCheckForm);
        }
        break;
      case 1:
        valuesCheckForm[1] = true;
        if (
          !form.state_id ||
          !form.city ||
          (!isVedacit && !isMc && !form.street) ||
          (!isVedacit && !isMc && !form.neighborhood) ||
          !form.number ||
          (!isVedacit && !isMc && !form.zip_code) ||
          !form.email ||
          (form.email && !validateEmail(form.email)) ||
          (!isSuvinil && !form.phone)
        ) {
          handleFormCheckValues(1, valuesCheckForm);
        } else {
          handleFormCheckValues(selectProgress ? page : 2, valuesCheckForm);
        }
        break;
      case 2:
        valuesCheckForm[2] = true;
        if (
          !form.password ||
          !form.password_confirmation ||
          (isVedacit || isMc ? !form.terms : !form.terms || !form.lgpd)
        ) {
          handleFormCheckValues(2, valuesCheckForm);
        } else {
          handleFormCheckValues(2, valuesCheckForm);
          if (form.password === form.password_confirmation) {
            dispatch(AuthActions.postCreateUserRequest(form));
          } else {
            setErrorPass(
              intl.messages["settings.textPage.passwordsDoNotMatch"],
            );
          }
        }
        break;
      default:
        valuesCheckForm[0] = false;
        handleFormCheckValues(2, valuesCheckForm);
    }
  };

  return (
    <S.ContainerFormSignUp>
      <S.ContainerRegister>
        {formCarrousel[formCheckValues.pageSelect]}
      </S.ContainerRegister>
      <S.ContainerButtonAndProgress>
        <Button
          type="button"
          isLoading={isLoading}
          variant={isVedacit ? ["vedacit"] : ["gradient"]}
          name={
            formCheckValues.pageSelect === 2
              ? intl.messages["general.buttons.finish"]
              : intl.messages["general.buttons.continue"]
          }
          onClick={() => handleSignUp(formCheckValues.pageSelect)}
          disabled={
            (isVedacit || isMc ? !form.terms : !form.terms || !form.lgpd) &&
            formCheckValues.pageSelect === 2
          }
        />
        <S.MonitoringProgress>
          {formCarrousel.map((page, index) => (
            <S.EclipsePage
              clientName={style.names.nameApp}
              key={index}
              progressForm={index <= formCheckValues.pageSelect}
              validateChecked={formCheckValues.checkFormList[index]}
              onClick={() =>
                formCheckValues.checkFormList[index] &&
                handleSignUp(index, true)
              }
            />
          ))}
        </S.MonitoringProgress>
      </S.ContainerButtonAndProgress>
    </S.ContainerFormSignUp>
  );
};

export default SignUp;
