import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Link, useParams, useHistory, useLocation } from "react-router-dom";
import { style } from "style";

import { useIntl } from "react-intl";
import { useAnalytics } from "utils/hooks";
import { Creators as TeamActions } from "store/ducks/team";
import { Creators as UserActions } from "store/ducks/user";
import { smartGet, smartFormatterDate } from "helpers/sharedHelpers";

import urls from "utils/constants/urls";
import Portal from "templates/Portal";
import Tags from "components/core/Tags";
import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { GoBackButton } from "containers/Mural/Publication/PublicationStyle";
import TitlePageComponent from "utils/TitlePageComponent";
import { PageTitle } from "components/core/Typography/Typography";

import { ReactComponent as IconTwitter } from "assets/img/icons/twitter.svg";
import { ReactComponent as IconFacebook } from "assets/img/icons/facebook.svg";
import { ReactComponent as IconLinkedin } from "assets/img/icons/linkedin.svg";
import { ReactComponent as IconInstagram } from "assets/img/icons/instagram.svg";
import { ReactComponent as GoBackIcon } from "assets/img/icons/goBackIcon.svg";
import { ReactComponent as IconCoins } from "assets/img/icons/iconCoins.svg";

import SkeletonProfile from "./SkeletonProfile";

import * as S from "./profileStyle";

useAnalytics("screen_profile_friend");

const Profile = () => {
  const intl = useIntl();
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { pageTitle } = location.state || "";
  const goBack = () => history.goBack();
  const { id } = useParams();
  const { user, personalQuestions } = useSelector((state) => state.user);
  const { isLoadingProfile, selectedProfile } = useSelector(
    (state) => state.team,
  );
  const {
    image,
    name,
    sector,
    bio,
    datebirthday,
    sex,
    show_gender,
    show_datebirthday,
    show_email,
    show_phone,
    interests,
    skills,
    contacts,
    tags,
    complement,
    score,
  } = selectedProfile;

  const questions = personalQuestions;

  const dispatchGetPersonalQuestions = useCallback(() => {
    dispatch(UserActions.getPersonalQuestions());
  }, [dispatch]);

  const day = smartGet(datebirthday, "date");
  const monthString = smartFormatterDate(datebirthday, "MMMM");
  const filterContact = (kind) =>
    contacts?.find((contact) => contact.kind === kind)?.contact;
  const dispatchGetProfile = useCallback(
    () => dispatch(TeamActions.getProfile(id)),
    [dispatch, id],
  );
  const dispatchCleanProfile = useCallback(
    () => dispatch(TeamActions.cleanProfile()),
    [dispatch],
  );

  useEffect(() => {
    dispatchGetProfile();
    dispatchGetPersonalQuestions();
    return () => dispatchCleanProfile();
  }, [dispatchGetPersonalQuestions, dispatchGetProfile, dispatchCleanProfile]);

  return (
    <Portal>
      <Flex flexDirection="column">
        <S.ContainerHeader>
          <PageTitle title={pageTitle || "Perfil"} noBorder="none" />
          {user?.id?.toString() === id &&
            localStorage.getItem("roles") !== "guest" && (
              <S.EditProfile>
                <Link to={urls.ROUTES.EDIT_PROFILE}>
                  {intl.messages["general.buttons.edit"]}
                </Link>
              </S.EditProfile>
            )}
        </S.ContainerHeader>
        {isLoadingProfile ? (
          <SkeletonProfile />
        ) : (
          <S.ContainerProfile>
            <TitlePageComponent title={name} />
            <GoBackButton onClick={goBack} isProfilePage>
              <GoBackIcon />
            </GoBackButton>
            <Flex p={4} height="100%" flexDirection="column" maxWidth="530px">
              <Flex
                alignItems="center"
                borderBottom="1px solid #D1D4E3"
                pb={3}
                mb={3}
              >
                <Avatar url={image?.url} size="120px" />
                <Flex ml={3} flexDirection="column">
                  <S.Username>{name.toLowerCase()}</S.Username>
                  <S.Team>{sector?.name.toLowerCase()}</S.Team>
                  <S.Complement>{complement?.toLowerCase()}</S.Complement>
                  <S.Social spaceChildren={3}>
                    {style.variables.gamificationEnabled && (
                      <>
                        {filterContact("facebook") !== undefined && (
                          <a
                            href={`https://www.facebook.com/${filterContact(
                              "facebook",
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconFacebook height="18px" />
                          </a>
                        )}
                        {filterContact("instagram") !== undefined && (
                          <a
                            href={`https://www.instagram.com/${filterContact(
                              "instagram",
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconInstagram height="18px" />
                          </a>
                        )}
                        {filterContact("twitter") !== undefined && (
                          <a
                            href={`https://www.twitter.com/${filterContact(
                              "twitter",
                            )}`}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <IconTwitter height="18px" />
                          </a>
                        )}
                      </>
                    )}
                    {filterContact("linkedin") !== undefined && (
                      <a
                        href={`https://www.linkedin.com/in/${filterContact(
                          "linkedin",
                        )}`}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        <IconLinkedin height="20px" />
                      </a>
                    )}
                  </S.Social>
                </Flex>
              </Flex>
              {user?.id === Number(id) && (
                <S.InfoSection>
                  <S.InfoLabel>
                    {intl.messages["profile.pontuation"]}
                  </S.InfoLabel>
                  <S.InfoValue>
                    <S.PontuationContainer>
                      <S.CoinsValue>
                        <IconCoins width={24} height={24} />
                        <S.Score>
                          {score} {intl.messages["general.textPage.nameCoins"]}
                        </S.Score>
                      </S.CoinsValue>
                      <S.EditProfile style={{ marginTop: "-1rem" }}>
                        <Link
                          to={urls.ROUTES.SCORE_HISTORY}
                          style={{ fontSize: "16px" }}
                        >
                          {intl.messages["general.textPage.viewHistory"]}
                        </Link>
                      </S.EditProfile>
                    </S.PontuationContainer>
                  </S.InfoValue>
                </S.InfoSection>
              )}

              {bio && questions[0] !== undefined && (
                <S.InfoSection>
                  <S.InfoLabel>{questions[0].title}</S.InfoLabel>
                  <S.InfoValue>{bio}</S.InfoValue>
                </S.InfoSection>
              )}

              {datebirthday && show_datebirthday && (
                <S.InfoSection>
                  <S.InfoLabel>Aniversário</S.InfoLabel>
                  <S.InfoValue>
                    {datebirthday && `${day} de ${monthString}`}
                  </S.InfoValue>
                </S.InfoSection>
              )}

              {filterContact("email") && show_email && (
                <S.InfoSection>
                  <S.InfoLabel>
                    {style.names.nameApp === "Pintou Parceria Suvinil"
                      ? "e-mail"
                      : "Email Corporativo"}
                  </S.InfoLabel>
                  <S.InfoValue color="secondaryColor">
                    {filterContact("email")}
                  </S.InfoValue>
                </S.InfoSection>
              )}

              {show_gender && sex && style.variables.gamificationEnabled && (
                <S.InfoSection>
                  <S.InfoLabel>Gênero</S.InfoLabel>
                  <S.InfoValue color="secondaryColor">{sex}</S.InfoValue>
                </S.InfoSection>
              )}

              {show_phone && filterContact("phone") && (
                <S.InfoSection>
                  <S.InfoLabel>Telefone</S.InfoLabel>
                  <S.InfoValue color="secondaryColor">
                    {filterContact("phone")}
                  </S.InfoValue>
                </S.InfoSection>
              )}

              {interests && questions[1] !== undefined && (
                <S.InfoSection>
                  <S.InfoLabel>{questions[1].title}</S.InfoLabel>
                  <S.InfoValue>{interests}</S.InfoValue>
                </S.InfoSection>
              )}

              {skills && questions[2] !== undefined && (
                <S.InfoSection>
                  <S.InfoLabel>{questions[2].title}</S.InfoLabel>
                  <S.InfoValue>{skills}</S.InfoValue>
                </S.InfoSection>
              )}
              {/* Attention: Hidden tags */}
              {/* <S.InfoSection>
                <S.InfoLabel>Tags</S.InfoLabel>
                <Tags tags={tags} />
              </S.InfoSection> */}
            </Flex>
          </S.ContainerProfile>
        )}
      </Flex>
    </Portal>
  );
};

export default Profile;
