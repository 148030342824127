/* eslint-disable no-use-before-define */
/* eslint-disable react/prop-types */
import React, { useState, useCallback, useRef } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory, useParams } from "react-router-dom";
import { useIntl } from "react-intl";
import {
  smartFormatterDate,
  handleCommentDateFns,
} from "helpers/sharedHelpers";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";

import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import { PageTitle } from "components/core/Typography/Typography";

import { Creators as PublicationActions } from "store/ducks/detailspublication";

import { ReactComponent as EditIcon } from "assets/img/icons/editIcon.svg";
import { ReactComponent as RemoveIcon } from "assets/img/icons/removeIcon.svg";
import { ReactComponent as ActionOptionsIcon } from "assets/img/icons/actionOptions.svg";
import { ReactComponent as IconClose } from "assets/img/icons/iconFechar.svg";
import { ReactComponent as SendIcon } from "assets/img/icons/send.svg";
import { ReactComponent as LikeIconDefault } from "assets/img/icons/iconLikeDefault.svg";
import { ReactComponent as LikeIcon } from "assets/img/icons/iconLike.svg";
import { ReactComponent as LovedIcon } from "assets/img/icons/iconLoved.svg";
import { ReactComponent as LaughterIcon } from "assets/img/icons/iconLaughter.svg";
import { ReactComponent as SurprisedIcon } from "assets/img/icons/iconSurprised.svg";
import { ReactComponent as SupportIcon } from "assets/img/icons/iconSupport.svg";
import { ReactComponent as CongratsIcon } from "assets/img/icons/iconCongrats.svg";

import { Creators as PublicationReactionActions } from "store/ducks/reactions";
import urls from "utils/constants/urls";
import { ModalProvider } from "styled-react-modal";
import { reactionsList } from "../../../../utils/Reactions/reactions";
import * as S from "../sidebarPublication/SidebarPublicationStyle";
import { Reactions } from "../../../../components/core/Reactions/Reactions";
import ReactionsViewModal from "../reactionsModal/ReactionsViewModal";

const Comments = ({
  canvas_comments,
  markUsersPublish,
  sendingComment,
  comments_amount,
  disableHover,
  isDesktop,
}) => {
  const intl = useIntl();
  const touchTimeOutRef = useRef();
  const dispatch = useDispatch();
  const { id } = useParams();
  const history = useHistory();
  const { user: currentUser } = useSelector(({ user }) => user);
  const commentedUser = canvas_comments?.find(
    ({ user }) => user?.id === currentUser?.id,
  );
  const [commentPublication, setCommentPublication] = useState("");
  const [idEditCommentPublication, setIdEditCommentPublication] = useState(0);
  const [idCommentAnswer, setIdCommentAnswer] = useState(0);
  const [showUserMarked, setShowUserMarked] = useState(false);
  const [nickname, setNickname] = useState("");
  const [showCommentReactions, setShowCommentReactions] = useState(null);
  const [openReactionsModal, setOpenReactionsModal] = useState(false);
  const [typeOfReaction, setTypeOfReaction] = useState("");
  const [shouldSaveAllReactions, setShouldSaveAllReactions] = useState(true);
  const [selectedId, setSelectedId] = useState();
  const [toogleCollapsed, setToogleCollapsed] = useState(false);

  const {
    reactions: commentsReactions,
    commentReactions,
    liked,
    isLoading: isReactionsLoading,
  } = useSelector(({ reactions }) => reactions);

  const handleEditComment = (idComment, valueComment) => {
    setCommentPublication(valueComment);
    setIdEditCommentPublication(idComment);
  };

  const handleKeyDown = (event) => {
    if (event.ctrlKey && event.keyCode === 13) {
      handleCreateComment(event);
    }
  };

  const dispatchGetMarkUsers = useCallback(
    (userName) =>
      dispatch(PublicationActions.getMarkUsers({ id, userSearch: userName })),
    [dispatch, id],
  );

  const handleMarkUserComment = (value) => {
    setCommentPublication(value);
    const lastElem = document.getElementById("commentArea").selectionStart;
    if (value[lastElem - 1] === "@") {
      setShowUserMarked(true);
    } else if (value[lastElem - 1] !== " ") {
      const startElem = value.lastIndexOf("@", lastElem);
      const stringPresent = value.substring(startElem + 1, lastElem);
      if (stringPresent.length > 1) {
        dispatchGetMarkUsers(stringPresent);
      }
    } else {
      setShowUserMarked(false);
    }
  };

  const handleCreateComment = (evt) => {
    evt.preventDefault();
    if (commentPublication.trim().length > 0) {
      if (idEditCommentPublication !== 0) {
        dispatch(
          PublicationActions.patchCommentPublication({
            id,
            idEditCommentPublication,
            comment: commentPublication,
          }),
        );
      } else if (idCommentAnswer !== 0) {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            repliedId: idCommentAnswer,
            comment: commentPublication,
          }),
        );
      } else {
        dispatch(
          PublicationActions.postCommentPublication({
            id,
            comment: commentPublication,
          }),
        );
      }
    }
    setNickname("");
    setIdCommentAnswer(0);
    setCommentPublication("");
    setIdEditCommentPublication(0);
  };

  const handleRemoveComment = (idComment) => {
    dispatch(PublicationActions.deleteCommentPublication({ id, idComment }));
  };

  const toggleCollapse = (index) => {
    const target = document.getElementById(`comment-${index}`);
    const toogleCollapsedAsk = target.classList.toggle("uncollapsed");
    setToogleCollapsed(toogleCollapsedAsk);
  };

  const handleSelectdUser = (nickName) => {
    const lastElem = document.getElementById("commentArea").selectionStart;
    const startElem = commentPublication.lastIndexOf("@", lastElem);
    const valueString = `${commentPublication.slice(
      0,
      startElem,
    )}@${nickName}${commentPublication.slice(lastElem)}`;
    setCommentPublication(valueString);
    setShowUserMarked(false);
    dispatchGetMarkUsers();
  };

  const handleCommentDate = (comment) => {
    const currentDate = smartFormatterDate(new Date(), "L").split("/");
    const commentData = smartFormatterDate(comment?.created_at, "L").split("/");

    if (currentDate[1] - commentData[1] >= 4) {
      return smartFormatterDate(comment?.created_at, "L");
    } else return handleCommentDateFns(comment?.created_at);
  };

  const currentUserHasReactedToComment = (comment) => {
    return comment.user_reaction?.user_id === currentUser.id;
  };

  const currentUserHasReactedToCommentReply = (answer) => {
    return answer.reply_user_reaction?.user_id === currentUser.id;
  };

  const reactionsIconsList = {
    like: { icon: LikeIcon, text: "Curtir" },
    loved_it: { icon: LovedIcon, text: "Amei" },
    laughter: { icon: LaughterIcon, text: "Risada" },
    surprized: { icon: SurprisedIcon, text: "Surpreso" },
    support: { icon: SupportIcon, text: "Apoio" },
    congrats: { icon: CongratsIcon, text: "Parabéns" },
  };

  const reactionDefault = {
    icon: LikeIconDefault,
    title: reactionsIconsList.like.text,
    value: reactionsList.like.value,
  };

  const handleCommentsReactions = (payload) => {
    const {
      canvasUserReactionsInfo,
      reaction,
      comment,
      isLikeButton,
      idComment,
      idPublication,
      commentUserId,
      idReaction,
      userReaction,
    } = payload;

    const currentUserHasReactedToComment = commentUserId === currentUser.id;

    if (isLikeButton && currentUserHasReactedToComment) {
      dispatch(
        PublicationReactionActions.deletePublicationCommentsReactions({
          idComment,
          reaction,
          idReaction,
          postId: idPublication,
        }),
      );
    } else if (!currentUserHasReactedToComment) {
      dispatch(
        PublicationReactionActions.createPublicationCommentsReactions({
          idComment,
          reaction,
          postId: idPublication,
        }),
      );
    } else if (currentUserHasReactedToComment && reaction !== userReaction) {
      dispatch(
        PublicationReactionActions.editPublicationCommentsReactions({
          idComment,
          idReaction,
          reaction,
          postId: idPublication,
        }),
      );
    }
  };

  const redirectToUserProfile = (comment, word) => {
    comment.user_markups.map((user) => {
      if (word.replace("@", "") === user.nickname) {
        history.push(`${urls.ROUTES.PROFILE}/${user.id}`);
      }
    });
  };

  const userIsMarkup = (comment, word) => {
    return (
      comment.user_markups.filter(
        (user) => user.nickname === word.replace("@", ""),
      ).length > 0
    );
  };

  const userIsMarkupInReply = (comment, word) => {
    return (
      comment.replies.map((reply) =>
        reply.user_markups.filter(
          (user) => user.nickname === word.replace("@", ""),
        ),
      ).length > 0
    );
  };

  const onHold = (commentId) => {
    setShowCommentReactions(commentId);
    touchTimeOutRef.current = [touchTimeOutRef.current[0], true];
  };

  const popoverEditComment = (valueComment, idComment) => (
    <S.StyledPopover>
      <S.ContainerPopoveActions>
        <PageTitle noBorder title="O que deseja fazer?" fontSize={2} />
        <S.ContainerOption>
          <S.OptionPopove
            type="button"
            onClick={() => handleEditComment(idComment, valueComment)}
          >
            <EditIcon />
            <S.TextOption>{intl.messages["general.buttons.edit"]}</S.TextOption>
          </S.OptionPopove>
        </S.ContainerOption>
        <S.ContainerOption>
          <S.OptionPopove
            type="button"
            onClick={() => handleRemoveComment(idComment)}
          >
            <RemoveIcon />
            <S.TextOption>
              {intl.messages["general.buttons.remove"]}
            </S.TextOption>
          </S.OptionPopove>
        </S.ContainerOption>
      </S.ContainerPopoveActions>
    </S.StyledPopover>
  );

  return (
    <S.ContainerComments>
      <S.CommentsHeader commented={commentedUser}>
        {comments_amount && comments_amount}{" "}
        {comments_amount > 1
          ? intl.messages["publication.textPage.comments"]
          : intl.messages["publication.textPage.commentSingular"]}
      </S.CommentsHeader>

      <S.OverflowComments>
        <Flex px={16} flexDirection="column" spaceChildren={4} pb={50}>
          {canvas_comments?.map(
            (comment) =>
              comment.replied_comment_id === null && (
                <S.Comment key={comment.id} spaceChildren={2}>
                  <Flex alignItems="center" justifyContent="space-between">
                    <Flex spaceChildren={2}>
                      <Avatar
                        url={comment.user?.image?.url}
                        width={32}
                        height={32}
                      />
                      <div>
                        <S.Username>{comment.user?.name}</S.Username>
                        <S.TimeAnswer>
                          {handleCommentDate(comment)}
                        </S.TimeAnswer>
                      </div>
                    </Flex>
                    {comment.user?.id === currentUser.id && (
                      <OverlayTrigger
                        trigger="click"
                        rootClose
                        placement="bottom"
                        overlay={popoverEditComment(comment.body, comment.id)}
                      >
                        <S.ContainerActionOptions>
                          <ActionOptionsIcon />
                        </S.ContainerActionOptions>
                      </OverlayTrigger>
                    )}
                  </Flex>
                  <S.UserComment>
                    {comment?.body.split(" ").map((word) =>
                      word[0] === "@" && userIsMarkup(comment, word) ? (
                        <S.MarkUser
                          key={word.id}
                          markedUser
                          onClick={() => redirectToUserProfile(comment, word)}
                        >
                          {word}
                        </S.MarkUser>
                      ) : (
                        <S.MarkUser key={word?.id}>{word}</S.MarkUser>
                      ),
                    )}
                  </S.UserComment>
                  {comment.total_reactions > 0 && (
                    <S.LikeCommentCounter
                      onClick={() => {
                        dispatch(
                          PublicationReactionActions.getPublicationCommentsReactions(
                            {
                              id: comment.id,
                              reaction: "",
                              page: 1,
                            },
                          ),
                        );
                        setTypeOfReaction("comment");
                        setOpenReactionsModal(true);
                        setShouldSaveAllReactions(true);
                        setSelectedId(comment.id);
                      }}
                    >
                      <>
                        <S.UsersLike style={{ marginRight: "8px" }}>
                          {Object.keys(comment.comment_reactions)
                            .sort(
                              (a, b) =>
                                comment.comment_reactions[b] -
                                comment.comment_reactions[a],
                            )
                            .slice(0, 3)
                            .map((commentReaction, index, array) => (
                              <Avatar
                                url={reactionsList[commentReaction].icon}
                                size="28px"
                                style={{
                                  zIndex: array.length - index,
                                }}
                              />
                            ))}
                        </S.UsersLike>
                        <S.Counter>{comment.total_reactions}</S.Counter>
                      </>
                    </S.LikeCommentCounter>
                  )}
                  <S.Divider />
                  <Flex justifyContent="space-between" alignItems="center">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        gap: "5px",
                      }}
                    >
                      <S.LikeCommentButton
                        icon={
                          currentUserHasReactedToComment(comment) &&
                          reactionsIconsList[comment.user_reaction?.reaction]
                            ?.icon
                        }
                        name={
                          currentUserHasReactedToComment(comment)
                            ? reactionsIconsList[
                                comment.user_reaction?.reaction
                              ]?.text
                            : "Curtir"
                        }
                        show={showCommentReactions === comment.id}
                        disableHover={disableHover}
                        onMouseUp={() => {
                          clearTimeout(touchTimeOutRef.current[0]);
                          if (!touchTimeOutRef.current[1]) {
                            setShowCommentReactions(null);
                            handleCommentsReactions({
                              idComment: comment.id,
                              reaction: "like",
                              isLikeButton: true,
                              comment,
                              commentUserId: comment.user_reaction?.user_id,
                              idReaction: comment.user_reaction?.reaction_id,
                              userReaction: comment.user_reaction?.reaction,
                              idPublication: id,
                            });
                          }
                        }}
                        onMouseDown={() => {
                          touchTimeOutRef.current = [
                            setTimeout(() => onHold(comment.id), 300),
                            false,
                          ];
                        }}
                      />
                      <Reactions
                        type="commentMobile"
                        idComment={comment.id}
                        commentReactions
                        currentUser={currentUser}
                        currentUserHasReacted={
                          comment?.user_reaction?.user_id === currentUser.id
                        }
                        commentUserId={comment.user_reaction?.user_id}
                        idReaction={comment.user_reaction?.reaction_id}
                        userReaction={comment.user_reaction?.reaction}
                        idPublication={id}
                        onEmojiClick={() => {
                          setShowCommentReactions(null);
                        }}
                      />
                    </div>
                    <S.TimeAnswer>
                      <Flex spaceChildren={2}>
                        <S.ViewAnswers
                          onClick={() => {
                            setIdCommentAnswer(comment.id);
                            setNickname(comment.user.nickname);
                          }}
                        >
                          Responder
                        </S.ViewAnswers>
                        {comment.replies.length > 0 && (
                          <S.ViewAnswers
                            onClick={() => toggleCollapse(comment.id)}
                          >
                            | {toogleCollapsed ? "Ocultar" : "Respostas"}
                          </S.ViewAnswers>
                        )}
                      </Flex>
                    </S.TimeAnswer>
                  </Flex>
                  <S.ContainerAnswers id={`comment-${comment.id}`}>
                    {comment?.replies.length > 0 && (
                      <>
                        <S.Divider />
                        {comment.replies.map((answer, index) => (
                          <Flex key={answer.id} flexDirection="column" ml={20}>
                            <Flex
                              alignItems="center"
                              justifyContent="space-between"
                            >
                              <Flex spaceChildren={2}>
                                <Avatar
                                  url={answer.user?.image?.url}
                                  width={32}
                                  height={32}
                                />
                                <div>
                                  <S.Username>{answer.user?.name}</S.Username>
                                  <S.TimeAnswer>
                                    {handleCommentDate(answer)}
                                  </S.TimeAnswer>
                                </div>
                              </Flex>
                              {answer.user?.id === currentUser.id && (
                                <OverlayTrigger
                                  trigger="click"
                                  rootClose
                                  placement="bottom"
                                  overlay={popoverEditComment(
                                    answer.body,
                                    answer.id,
                                  )}
                                >
                                  <S.ContainerActionOptions>
                                    <ActionOptionsIcon />
                                  </S.ContainerActionOptions>
                                </OverlayTrigger>
                              )}
                            </Flex>
                            <S.UserComment id={`reply-${answer.id}`}>
                              {answer?.body.split(" ").map((word) =>
                                word[0] === "@" &&
                                userIsMarkupInReply(comment, word) ? (
                                  <S.MarkUser
                                    key={word.id}
                                    markedUser
                                    onClick={() =>
                                      redirectToUserProfile(comment, word)
                                    }
                                  >
                                    {word}
                                  </S.MarkUser>
                                ) : (
                                  <S.MarkUser key={word.id}>{word}</S.MarkUser>
                                ),
                              )}
                            </S.UserComment>
                            {answer.reply_total_reactions > 0 && (
                              <S.LikeCommentCounter
                                onClick={() => {
                                  dispatch(
                                    PublicationReactionActions.getPublicationCommentsReactions(
                                      {
                                        id: answer.id,
                                        reaction: "",
                                        page: 1,
                                      },
                                    ),
                                  );
                                  setTypeOfReaction("comment");
                                  setOpenReactionsModal(true);
                                  setShouldSaveAllReactions(true);
                                  setSelectedId(answer.id);
                                }}
                              >
                                <>
                                  <S.UsersLike style={{ marginRight: "8px" }}>
                                    {Object.keys(answer.reply_reactions)
                                      .sort(
                                        (a, b) =>
                                          answer.reply_reactions[b] -
                                          answer.reply_reactions[a],
                                      )
                                      .slice(0, 3)
                                      .map(
                                        (
                                          commentReactionAnswer,
                                          index,
                                          array,
                                        ) => (
                                          <Avatar
                                            url={
                                              reactionsList[
                                                commentReactionAnswer
                                              ].icon
                                            }
                                            size="28px"
                                            style={{
                                              zIndex: array.length - index,
                                            }}
                                          />
                                        ),
                                      )}
                                  </S.UsersLike>
                                  <S.Counter>
                                    {answer.reply_total_reactions}
                                  </S.Counter>
                                </>
                              </S.LikeCommentCounter>
                            )}
                            <S.Divider />
                            <Flex
                              justifyContent="space-between"
                              alignItems="center"
                            >
                              <div
                                style={{
                                  display: "flex",
                                  flexDirection: "row",
                                  alignItems: "center",
                                  gap: "5px",
                                }}
                              >
                                <S.LikeCommentButton
                                  icon={
                                    currentUserHasReactedToCommentReply(
                                      answer,
                                    ) &&
                                    reactionsIconsList[
                                      answer.reply_user_reaction?.reaction
                                    ]?.icon
                                  }
                                  name={
                                    currentUserHasReactedToCommentReply(answer)
                                      ? reactionsIconsList[
                                          answer.reply_user_reaction?.reaction
                                        ]?.text
                                      : "Curtir"
                                  }
                                  show={showCommentReactions === answer.id}
                                  disableHover={disableHover}
                                  onMouseUp={() => {
                                    clearTimeout(touchTimeOutRef.current[0]);
                                    if (!touchTimeOutRef.current[1]) {
                                      setShowCommentReactions(null);
                                      handleCommentsReactions({
                                        idComment: answer.id,
                                        reaction: "like",
                                        isLikeButton: true,
                                        comment: answer,
                                        commentUserId:
                                          answer.reply_user_reaction?.user_id,
                                        idReaction:
                                          answer.reply_user_reaction
                                            ?.reaction_id,
                                        userReaction:
                                          answer.reply_user_reaction?.reaction,
                                        idPublication: id,
                                      });
                                    }
                                  }}
                                  onMouseDown={() => {
                                    touchTimeOutRef.current = [
                                      setTimeout(() => onHold(answer.id), 300),
                                      false,
                                    ];
                                  }}
                                />
                                <Reactions
                                  type="commentReplyMobile"
                                  idComment={answer.id}
                                  commentReactions
                                  currentUser={currentUser}
                                  commentUserId={
                                    answer.reply_user_reaction?.user_id
                                  }
                                  idReaction={
                                    answer.reply_user_reaction?.reaction_id
                                  }
                                  userReaction={
                                    answer.reply_user_reaction?.reaction
                                  }
                                  idPublication={id}
                                  onEmojiClick={() => {
                                    setShowCommentReactions(null);
                                  }}
                                />
                              </div>
                            </Flex>
                            {index !== comment?.replies.length - 1 && (
                              <S.Divider />
                            )}
                          </Flex>
                        ))}
                      </>
                    )}
                  </S.ContainerAnswers>
                </S.Comment>
              ),
          )}
        </Flex>
      </S.OverflowComments>

      <S.CommentForm onSubmit={handleCreateComment}>
        <Flex width={1} p={3} flexDirection="column">
          <S.ComponentExitComment
            showCommentPublication={idEditCommentPublication || idCommentAnswer}
          >
            <S.ButtonCloseComment
              onClick={() => {
                setNickname("");
                setIdCommentAnswer(0);
                setCommentPublication("");
                setIdEditCommentPublication(0);
              }}
              type="button"
            >
              <IconClose />
            </S.ButtonCloseComment>
            <S.TextExitComment>
              {idCommentAnswer !== 0 ? `Respodendo @${nickname}` : "Editando"}
            </S.TextExitComment>
          </S.ComponentExitComment>
          <Flex
            spaceChildren={1}
            justifyContent="space-evenly"
            alignItems="center"
          >
            <Avatar url={currentUser?.image?.url} width="28px" />
            <S.ContainerInputArea>
              <S.ComponentUsersMarked userMarked={showUserMarked}>
                {markUsersPublish.length > 0 &&
                  markUsersPublish.map((user) => (
                    <S.ContainerUser
                      key={user.id}
                      onClick={() => handleSelectdUser(user.nickname)}
                    >
                      <Avatar url={user?.image?.url} size={28} />
                      <S.NameUser>{user.name}</S.NameUser>
                    </S.ContainerUser>
                  ))}
              </S.ComponentUsersMarked>
              <S.CommentInput
                id="commentArea"
                value={commentPublication}
                onChange={(evt) => handleMarkUserComment(evt.target.value)}
                onKeyDown={handleKeyDown}
                placeholder={
                  intl.messages[
                    "publication.textPage.commentInputResponsivePlaceholder"
                  ]
                }
                showCommentPublication={
                  idEditCommentPublication || idCommentAnswer
                }
              />
            </S.ContainerInputArea>
            <S.SendComment
              isLoading={sendingComment}
              disabled={commentPublication.trim().length === 0}
            >
              {!sendingComment && <SendIcon />}
            </S.SendComment>
          </Flex>
        </Flex>
      </S.CommentForm>

      {openReactionsModal && (
        <ModalProvider>
          <ReactionsViewModal
            openReactionsModal={openReactionsModal}
            shouldSaveAllReactions={shouldSaveAllReactions}
            setOpenReactionsModal={setOpenReactionsModal}
            setShouldSaveAllReactions={setShouldSaveAllReactions}
            setTypeOfReaction={setTypeOfReaction}
            typeOfReaction={typeOfReaction}
            isReactionsLoading={isReactionsLoading}
            reactions={commentsReactions}
            id={selectedId}
            isDesktop={isDesktop}
          />
        </ModalProvider>
      )}
    </S.ContainerComments>
  );
};

export default Comments;
