/* eslint-disable react/prop-types */
/* eslint-disable no-unused-expressions */
import React, { useState } from "react";
import ReactPlayer from "react-player";

import { useDispatch } from "react-redux";
import { useIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { Creators as MuralActions } from "store/ducks/mural";
import { Creators as ModalAlertActions } from "store/ducks/modalAlert";

import Flex from "components/core/Grid/Flex";
import Avatar from "components/core/Avatar/Avatar";
import Carousel from "components/core/Carousel";
import Quiz from "components/presentation/Quiz";
import Survey from "components/presentation/Survey";
import urls from "utils/constants/urls";

import {
  convertToSlug,
  manageClasses,
  smartFormatterDate,
} from "helpers/sharedHelpers";

import { ReactComponent as IconPin } from "assets/img/icons/iconFixed.svg";
import { ReactComponent as IconComment } from "assets/img/icons/iconFeedComment.svg";

import * as S from "./MuralStyle";
import { reactionsList } from "../../../utils/Reactions/reactions";

const Mural = ({ publications }) => {
  const intl = useIntl();
  const history = useHistory();
  const dispatch = useDispatch();
  const [quizzes, setQuizzes] = useState([]);
  const [surveys, setSurveys] = useState([]);
  const idUser = localStorage.getItem("idUser");
  const { videoAutoplay } =
    JSON.parse(localStorage.getItem(`localConfig-${idUser}`)) || {};

  const isForm = (kind) => kind === "survey" || kind === "quiz";

  const handleQuiz = (updatedQuiz) => {
    const selectedQuiz = quizzes.findIndex(
      (quiz) => quiz.canvas_content_id === updatedQuiz.canvas_content_id,
    );

    if (selectedQuiz === -1) {
      setQuizzes([...quizzes, updatedQuiz]);
    } else {
      setQuizzes(
        quizzes.map((quiz, index) =>
          index === selectedQuiz ? updatedQuiz : quiz,
        ),
      );
    }
  };

  const sendQuiz = (postId, dataId) => {
    const selectedQuiz = quizzes.find(
      ({ canvas_content_id }) => canvas_content_id === postId,
    );

    dispatch(
      ModalAlertActions.setAlertsRequest({
        title: intl.messages["modal.textPage.submitTheAnswer"],
        msg: intl.messages[
          "publication.textPage.youEillNotBeAbleToChangeTheInformationLater"
        ],
        buttons: [
          {
            title: intl.messages["general.buttons.cancel"],
            variant: ["transparent"],
          },
          {
            title: intl.messages["general.buttons.send"],
            event: () =>
              dispatch(
                MuralActions.quizPublicationsRequest({
                  idPublication: selectedQuiz.canvas_content_id,
                  idForm: dataId,
                  answer: selectedQuiz.surveys_ids,
                }),
              ),
          },
        ],
      }),
    );
  };

  const handleSurvey = (updatedSurvey) => {
    const selectedSurvey = surveys.findIndex(
      (survey) => survey.canvas_content_id === updatedSurvey.canvas_content_id,
    );

    if (selectedSurvey === -1) {
      setSurveys([...surveys, updatedSurvey]);
    } else {
      setSurveys(
        surveys.map((survey, index) =>
          index === selectedSurvey ? updatedSurvey : survey,
        ),
      );
    }
  };

  const handleFavorite = (id, favorite) => {
    favorite
      ? dispatch(
          MuralActions.deleteFavoritePublicationRequest({
            id,
          }),
        )
      : dispatch(
          MuralActions.postFavoritePublicationRequest({
            id,
          }),
        );
  };

  const sendSurvey = (surveyId) => {
    const selectedSurvey = surveys.find(
      ({ canvas_content_id }) => canvas_content_id === surveyId,
    );

    dispatch(
      ModalAlertActions.setAlertsRequest({
        title: intl.messages["modal.textPage.submitTheAnswer"],
        msg: intl.messages[
          "publication.textPage.youEillNotBeAbleToChangeTheInformationLater"
        ],
        buttons: [
          {
            title: intl.messages["general.buttons.cancel"],
            variant: ["transparent"],
          },
          {
            title: intl.messages["general.buttons.send"],
            event: () =>
              dispatch(
                MuralActions.surveyPublicationsRequest({
                  idPublication: selectedSurvey.canvas_content_id,
                  idOption: selectedSurvey.survey_id,
                }),
              ),
          },
        ],
      }),
    );
  };

  const renderPostData = (post, goToUrl) => {
    const { postId, kind, dataId, data, allVotesAmount, end_date } = post;
    switch (kind) {
      case "image":
        return <S.Image onClick={() => history.push(goToUrl)} src={data} />;
      case "video":
        return (
          <video
            key={postId}
            onClick={() => history.push(goToUrl)}
            autoPlay={videoAutoplay || false}
            width="100%"
            height="325px"
            preload
            loop
            muted
            controls
            controlsList="nodownload"
          >
            <source src={data} type="video/mp4" />
          </video>
        );
      case "embeded_video_link":
        return (
          <ReactPlayer
            url={data}
            width="100%"
            controls
            muted
            playing={videoAutoplay}
          />
        );
      case "carousel":
        return (
          <Carousel
            key={postId}
            showButtonCarousel
            itensRender={data?.map((url) => (
              <S.Image onClick={() => history.push(goToUrl)} src={url} />
            ))}
          />
        );
      case "quiz":
        return (
          <Flex key={postId} m={20} flexDirection="column" spaceChildren={3}>
            <Quiz
              contentId={postId}
              handleQuiz={handleQuiz}
              options={data}
              sendQuiz={() => sendQuiz(postId, dataId)}
              allVotesAmount={allVotesAmount}
              endDate={end_date}
            />
          </Flex>
        );
      case "survey": {
        return (
          <Flex key={postId} m={20} flexDirection="column" spaceChildren={3}>
            <Survey
              contentId={postId}
              handleSurvey={handleSurvey}
              options={data}
              sendSurvey={() => sendSurvey(postId)}
              allVotesAmount={allVotesAmount}
              endDate={end_date}
            />
          </Flex>
        );
      }
      default:
        return null;
    }
  };

  const PostTags = ({ tags }) => (
    <Flex height={24}>
      <S.TagStyle key={tags[0].id}>{tags[0].name}</S.TagStyle>
      {tags.length > 1 && (
        <>
          <S.TagStyle>+{tags.length - 1}</S.TagStyle>
          {tags[2] && <S.TagStyle />}
          {tags[3] && <S.TagStyle />}
        </>
      )}
    </Flex>
  );

  return publications?.map(
    ({
      id,
      favorite,
      fixed,
      kind,
      title,
      date,
      dataId,
      data,
      author,
      tags,
      allVotesAmount,
      end_date,
      comments_amount,
      likes,
      reactions,
    }) => {
      const goToUrl = `${urls.ROUTES.DETAILS_PUBLICATION}/${convertToSlug(
        title,
      )}/${id}`;

      return (
        <S.Post
          key={id}
          variant={manageClasses({
            inverted: kind === "survey" || kind === "quiz",
          })}
          title={
            !isForm(kind) &&
            `${intl.messages["general.textPage.goTo"]}: ${title}`
          }
        >
          {renderPostData(
            { postId: id, kind, dataId, data, allVotesAmount, end_date },
            goToUrl,
          )}
          <S.Header onClick={() => !isForm(kind)}>
            <S.PostTitle onClick={() => history.push(goToUrl)}>
              {title}
            </S.PostTitle>
            <Flex justifyContent="space-between" alignItems="center">
              <S.Author>
                <Avatar
                  url={author?.image}
                  title={`${intl.messages["general.textPage.goToProfile"]}: ${author?.name}`}
                  onClick={() =>
                    history.push(`${urls.ROUTES.PROFILE}/${author?.id}`)
                  }
                />
                <Flex flexDirection="column">
                  <S.Username>{author?.name}</S.Username>
                  {isForm(kind) ? (
                    <S.Date>
                      {smartFormatterDate(date, "DD/MM/YYYY")} - Respostas até{" "}
                      {smartFormatterDate(end_date, "DD/MM/YYYY")}
                    </S.Date>
                  ) : (
                    <S.Date>{smartFormatterDate(date, "DD/MM/YYYY")}</S.Date>
                  )}
                </Flex>
              </S.Author>
              <S.ContainerTest>
                {likes > 0 && (
                  <>
                    <S.UsersReactions>
                      {Object.keys(reactions)
                        .slice(0, 3)
                        .map((reaction, index, array) => (
                          <Avatar
                            url={reactionsList[reaction].icon}
                            size="28px"
                            style={{
                              zIndex: array.length - index,
                            }}
                          />
                        ))}
                    </S.UsersReactions>
                    <S.Counter>{likes}</S.Counter>
                  </>
                )}

                {fixed && (
                  <S.FixedPost>
                    <IconPin />
                  </S.FixedPost>
                )}
                {comments_amount > 0 && (
                  <S.CommentsContainer>
                    <IconComment />
                    <S.Counter style={{ marginLeft: "5px" }}>
                      {comments_amount}
                    </S.Counter>
                  </S.CommentsContainer>
                )}

                {/* Attention: Hidden tags */}
                {/* <PostTags tags={tags} /> */}
              </S.ContainerTest>
            </Flex>
          </S.Header>
        </S.Post>
      );
    },
  );
};

export default Mural;
